const deviceprofile = () => {
    let deviceprofile;
    if (window.jitdevizr.isDevicePc()) {
        deviceprofile = 'pc_3cat';
    } else if (window.jitdevizr.isDeviceTablet()) {
        deviceprofile = 'tablet_3cat';
    } else if (window.jitdevizr.isDeviceMobile()) {
        deviceprofile = 'mobil_3cat';
    } else if (window.jitdevizr.isDeviceTv()) {
        deviceprofile = 'tv_3cat';
    }
    return deviceprofile;
};

const isAdaptativeVoDSupported = () => {
    const isIENotEdge = window.jitdevizr.isIE() && !window.jitdevizr.isEdge();
    const isSafari = window.jitdevizr.isSafari();
    if (window.jitdevizr.isiOS() || (window.jitdevizr.isMacintosh() && isSafari)) {
        return '&format=hm';
    } else if (window.jitdevizr.isAndroid() || (window.jitdevizr.isDevicePc() && !isIENotEdge && !isSafari)) {
        return '&format=dm';
    }
    return '&format=m';
};

export function constructMediaUrl(objParams) {
    let { urlBase } = objParams;
    const { tipus, dataParametresExtraWs, directe, mediaws, versio, id, offset } = objParams;
    const isVideo = tipus === 'video';
    //const isCertifiedDomain = false; //prové del isResta del media-view del portal isCertifiedDomain or isResta
    //let replaceBy = isCertifiedDomain ? '&embed=true' : ''; //TODO: revisar, treiem per avis de sonar.
    let replaceBy = '';
    const vodAdaptativeParams = isVideo && !directe ? isAdaptativeVoDSupported() : '';

    if (dataParametresExtraWs) {
        replaceBy += '&' + dataParametresExtraWs;
    }

    if (replaceBy.indexOf('profile=') >= 0) {
        urlBase = urlBase.replace('&profile=:deviceprofile:extrawsparams', replaceBy).concat(vodAdaptativeParams);
    } else {
        urlBase = urlBase
            .replace(':deviceprofile', deviceprofile)
            .replace(':extrawsparams', replaceBy)
            .concat(vodAdaptativeParams);
    }
    return urlBase
        .replace(':versio', versio)
        .replace(':mediaws', mediaws)
        .replace(':idint', id)
        .replace(':versio', versio)
        .replace(':offset', offset);
}

export function getParametresExtra(parametresExtraWs, params) {
    parametresExtraWs.split('&').forEach((element) => {
        const p = element.split('=');
        if (!params[p[0]]) params[p[0]] = p[1];
    });

    return Object.entries(params)
        .filter(([key, value]) => value !== undefined)
        .flatMap(([key, value]) => [value].flat().map((v) => [key, v]))
        .map((it) => it.join('='))
        .join('&');
}
