import styles from '../player.module.scss';
import Icona from 'components/3cat/ui/Icona';
import CodiEspectador from 'components/3cat/CodiEspectador';
import __isEmpty from 'lodash/isEmpty';

export default function FullScreen(props) {
    const { titol, nomProg, textAud, changeAudioDesc, aud } = props;
    let { edat } = props;

    edat = edat?.replace('Aquest àudio és per majors de ', '').replace(' anys', '');
    let altEdat = edat?.replace('CE_', '') + ' anys';

    return (
        <div className={styles.fullscreenrow}>
            <div className={styles.nom}>{nomProg}</div>
            <div className={styles.titol}>{titol}</div>
            <div className={styles.subinfo}>
                {edat !== 'CE_TP' && !__isEmpty(edat) && (
                    <span className={styles.edat}>
                        <CodiEspectador codi={edat} alt={altEdat} mida='20' className='' />
                    </span>
                )}
                {aud && (
                    <button className={styles.boto} aria-label='audiodescripció' onClick={changeAudioDesc}>
                        <div className={styles.boto_text}>{textAud}</div>
                        <Icona estil='clickable' alt='Icona audiodescripció' icona='audiodescripcio' />
                    </button>
                )}
            </div>
        </div>
    );
}
